import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { getUser, signOut as sendSignOutRequest, signInSSO as sendsignInSSORequest } from '../api/auth';

function AuthProvider(props) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async function () {
            const result = await getUser();

            if (result.is_success) {
                setUser(result.data);
            } else {
                setUser();
                clear_jwt_token();
            }

            setLoading(false);
        })();
    }, []);

    const set_jwt_token = (token) => {
        const access_token = token.split(' ')[0];
        const refresh_token = token.split(' ')[1];

        localStorage.access_token = access_token;
        localStorage.refresh_token = refresh_token;

    }
    const clear_jwt_token = () => {
        localStorage.access_token = null;
        localStorage.refresh_token = null;
    }

    const signOut = useCallback(async () => {
        const result = await sendSignOutRequest();
        clear_jwt_token();
        setUser();

    }, []);

    const signInSSO = async (ticket_key, hash) => {
        const res = await sendsignInSSORequest(ticket_key, hash);
        if (res.is_success) {
            setUser(res.data);
            set_jwt_token(res.message);
            return true;
        } else {
            clear_jwt_token()
        }
        return false;
    }

    return (
        <AuthContext.Provider value={{ user, signInSSO, signOut, loading }} {...props} />
    );
}

const AuthContext = createContext({});
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth }


