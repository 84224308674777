import LoadPanel from 'devextreme-react/load-panel';
import 'devextreme/dist/css/dx.common.css';
import pMinDelay from 'p-min-delay';
import React from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css';
import app_info from './app-info';
import { Loadable } from './components/loadable';
import LoaderPage from './components/loader-page';
import { AuthProvider, useAuth } from './contexts/auth';
import { NavigationProvider } from './contexts/navigation';
import StoreProvider from "./contexts/store";
import { TranslateProvider } from './contexts/translate';
import './dx-styles.scss';
import './style.css';
import './themes/generated/theme.additional.css';
import './themes/generated/theme.base.css';
import { useScreenSizeClass } from './utils/media-query';

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}
const pMinDelayTime = 250;
export const ContentPage = Loadable(() => pMinDelay(import('./Content'), pMinDelayTime), { fallback: <LoaderPage /> });
export const NotAuthenticatedContentPage = Loadable(() => pMinDelay(import('./NotAuthenticatedContent'), pMinDelayTime), { fallback: <LoaderPage /> });

function App() {
    const { user, loading, signOut } = useAuth();

    const query = useQuery();
    const hash = query.get("hash");
    const user_id = query.get("user");
    const ticket_key = query.get("key");
    if (loading) {
        return <LoadPanel visible={true} />;
    }
    if (user) {
        if (user_id != null && user_id != user.id) {
            signOut();
        } else {
            return <ContentPage />;
        }

    }
    else {
        if (hash != null && ticket_key != null) {
            return <NotAuthenticatedContentPage />
        } else {
            window.location.href = app_info.home_url + "/login?service=" + window.location.origin + "/login&system_id=" + app_info.sub_system_id;
            return <LoadPanel visible={true} />;
        }
    }

}


export default function () {
    const screenSizeClass = useScreenSizeClass();

    return (
        <Router>
            <AuthProvider>
                <StoreProvider>
                    <NavigationProvider>
                        <TranslateProvider>
                            <div className={`app ${screenSizeClass}`}>
                                <App />
                            </div>
                        </TranslateProvider>
                    </NavigationProvider>
                </StoreProvider>
            </AuthProvider>
        </Router>
    );
}
